import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import { Link } from '@reach/router';



import './services.scss'

const Services = () => (
    <StaticQuery
        query={graphql`
            query {

              allNodeServices(sort: {fields: field_weight, order: ASC}) {
                edges {
                  node {
                    id
                    title
                    path {
                      alias
                    }
                    body {
                      value
                      format
                      processed
                      summary
                    }
                    relationships {
                      field_media_img {
                        relationships {
                          field_media_image {
                            localFile {
                              childImageSharp {
                                fixed(width: 573, height: 344) {
                                  src
                                }
                                original {
                                  width
                                  height
                                  src
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    relationships {
                      field_icon_second {
                        relationships {
                          field_media_image {
                            localFile {
                              childImageSharp {
                                fixed(width: 71, quality: 100) {
                                  src
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        `} 

        render={data => (
            <div className="wd-services-listing">
                <div className="wd-services-listing__container">
                    {data.allNodeServices.edges.map(({ node }) => (
                        <div className="wd-services-listing__service" key={node.id}>
                            <div className="wd-services-listing__imgs-box">
                                {node.relationships.field_media_img.relationships.field_media_image != null &&
                                    <Img className="wd-services-listing__img" fixed={node.relationships.field_media_img.relationships.field_media_image.localFile.childImageSharp.fixed} />
                                }

                                {node.relationships.field_icon_second.relationships.field_media_image != null &&
                                    <Img className="wd-services-listing__icon" fixed={node.relationships.field_icon_second.relationships.field_media_image.localFile.childImageSharp.fixed} />
                                }
                            </div>
                            
                            <div className="wd-services-listing__title-desc-box">
                                <Link to={node.path.alias} className="wd-services-listing__title">{node.title}</Link>
                                <div className="wd-services-listing__description" dangerouslySetInnerHTML={{ __html: node.body.summary }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>  
        )}
    />
)

export default Services;