import React from 'react'
import './detailHeader.scss'

const DetailHeader = (props) => {
    return(
        <div className="wd-detail-header">
            <div className="wd-detail-header__container">
                <h2 className="wd-detail-header__title">{props.title}</h2>
            </div>
        </div>
    )
}

export default DetailHeader



